<template>
    <span>Signing out...</span>
</template>

<script>
import Api from '../../scripts/api'
export default {
    async created() {
        try {
            await Api.accountSignout();
        } catch {
            // Failed to signout via HTTP request. Attempt to signout via cookie deletion.
            try {
                document.cookie = 'sk=; Path=/;';
            } catch{ /* */ }
        }

        // Full site reload. Refreshing via router will not work,
        // as that would not trigger re-getting auth info.
        window.location.replace("/");
    }
}
</script>